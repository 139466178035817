import { getCssText, sroStyle } from '@amedia/brick-tokens';

import { componentStyle } from './styles.js';
import type { BrickTeaserPlayerData } from './types';
import { brickImageSrcset } from '@amedia/brick-image/template';

export const convertSecondsToTime = (seconds: string): string => {
  const totalSeconds = parseInt(seconds, 10);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;

  if (hours > 0) {
    return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  } else {
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  }
};

const camelToKebabCase = (str: string): string => {
  return str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
};

const buildAttributes = (data: BrickTeaserPlayerData): string => {
  return Object.entries(data)
    .filter(
      ([_, value]) => value !== undefined && value !== null && value !== ''
    )
    .map(([key, value]) => {
      const kebabKey = camelToKebabCase(key);
      return `${kebabKey}="${value}"`;
    })
    .join(' ');
};

/**
 * Returns the title of the next item, prefixed with "(+)" if the item is marked as premium.
 *
 * @param data - An object containing properties related to the next item, including `dataNextPremium` and `dataNextTitle`.
 * @returns The next title, optionally prefixed with "(+)" if the item is premium.
 */
const getNextTitle = (dataNextTitle, dataNextPremium) => {
  if (!dataNextTitle) {
    return '';
  }
  if (dataNextPremium === 'true') {
    return `<h3>(+) ${dataNextTitle}</h3>`;
  }
  return `<h3>${dataNextTitle}</h3>`;
};

const getImageMarkup = (dataNextPosterUrl: string | undefined) => {
  if (!dataNextPosterUrl)
    return '<brick-icon-v2 data-icon-id="play" data-icon-text="Video spiller illustrasjon"></brick-icon-v2>';
  const srcset = brickImageSrcset({
    srcsizes: '100_100',
    url: dataNextPosterUrl,
    encodeUri: false,
  });

  return `<brick-image-v3 data-loading="false" data-width="200" data-height="200" data-srcsize="200_200" data-alt-text="" data-src="${dataNextPosterUrl}" data-srcset="${srcset}" data-sizes="80px"></brick-image-v3>`;
};

const createButtonMarkup = (iconId: string, iconText: string) => {
  return `<brick-button-v7 class="${iconId}" data-iconid="${iconId}" data-icontext="${iconText}" data-aria-label="${iconText} data-adp-clicklabel="video-recommend" data-adp-clickvalue="${iconId}" data-size="small"></brick-button-v7>`;
};

/**
 * Generates the HTML markup for the overlay that recommends the next video.
 *
 * @param data - An object containing properties related to the next video,
 * including `dataNextDuration`, `dataNextAcpId`, `dataNextPermalink`,
 * `dataNextPremium`, `dataNextTitle`, and `dataNextPosterUrl`.
 *
 * @returns A string of HTML markup for the overlay, including metadata,
 * buttons for replay and close actions, and a recommendation prompt.
 * The overlay is hidden with CSS if the `brick-teaser-player` is not defined.
 */
const renderOverlay = (data: BrickTeaserPlayerData) => {
  const durationMarkup = data.dataNextDuration
    ? `<span class="duration">${convertSecondsToTime(data.dataNextDuration)}</span>`
    : '';
  const playButton = createButtonMarkup(
    'replay',
    'Lukk dialogen og spill av video'
  );
  const closeButton = createButtonMarkup('close', 'Lukk dialogen');
  const inlineStyle = `
    <style>
      brick-teaser-player:not(:defined) article.recommend-overlay {
        display: none;
      }
    </style>
  `;
  return `${inlineStyle}<article itemscope itemtype="http://schema.org/NewsArticle" class="recommend-overlay" data-recommend-overlay>
    <span class="sro ${sroStyle}">Vil du se neste video?</span>
    <div class="buttons">${closeButton}</div>
    <meta itemprop="adpType" content="teaser" />
    <meta itemprop="identifier" content="${data.dataNextAcpId}" />
    <meta itemprop="url" content="${data.dataNextPermalink}" />
    <meta itemprop="contentModel" content="${data.dataNextPremium ? 'premium' : 'free'}" />
    <meta itemprop="headline" content="${data.dataNextTitle}" />
    <meta itemprop="productFeature" content="video-recommend" />
    <a href="${data.dataNextPermalink}" class="recommend-poster" data-adp-clicklabel="video-recommend" data-adp-clickvalue="poster">
      <div class="image-column">${getImageMarkup(data.dataNextPosterUrl)}</div>
      <div class="text-column">${getNextTitle(data.dataNextTitle, data.dataNextPremium)}${durationMarkup}</div>
    </a>
    <div class="buttons">${playButton}</div>
  </article>`;
};

/**
 * @description
 * Returns a string of HTML markup with a video player and an overlay with a recommendation prompt.
 *
 * @param {BrickTeaserPlayerData}
 * @param {boolean} [isRendered] If true, the markup is rendered serverside and
 * the DOM should not be updated client side.
 * @returns {string} A string of HTML markup for the brick-teaser-player component.
 */
export const brickTeaserPlayerTemplate = (
  data: BrickTeaserPlayerData,
  isRendered?: boolean
): string => {
  const {
    dataTitle,
    dataMediaId,
    dataPlayerId,
    dataAspectRatio = '1:1',
    dataAutoplay = 'false',
    dataAdsDisabled,
    dataMuted = 'true',
    dataStart,
  } = data;
  const playerAttributes: string[] = [];

  if (dataAspectRatio) {
    playerAttributes.push(`aspectratio="${dataAspectRatio}"`);
  }
  if (dataAutoplay) {
    playerAttributes.push(`autoplay="${dataAutoplay}"`);
  }
  if (dataMuted) {
    playerAttributes.push(`muted="${dataMuted}"`);
  }
  if (dataAdsDisabled) {
    playerAttributes.push(`adsdisabled="${dataAdsDisabled}"`);
  }
  if (dataStart) {
    playerAttributes.push(`start="${dataStart}"`);
  }

  if (dataTitle) {
    playerAttributes.push(`title="${dataTitle}"`);
  }
  const playerMarkup = `<brick-player ${playerAttributes.join(' ')} teasertitle="true" mediaid=${dataMediaId} playerid="${dataPlayerId}"></brick-player>`;
  const markup = `${playerMarkup}${renderOverlay(data)}`;

  if (isRendered === true) {
    const attributes = buildAttributes(data);
    // componentStyles = `${getCssText()}`;
    return `<brick-teaser-player class="${componentStyle}" ${attributes} is-rendered >${markup}</brick-teaser-player>`;
  }
  return `${markup}`;
};

export const renderBrickTeaserPlayer = (
  dataset: BrickTeaserPlayerData
): string => {
  const isRendered = true;
  return brickTeaserPlayerTemplate(
    {
      ...dataset,
    },
    isRendered
  );
};

export { getCssText, sroStyle };
export type { BrickTeaserPlayerData };
